import React from "react";
import { Typography, Link, Paper } from "@mui/material";

function RoamPolicyDocument() {
  return (
    <Paper
      style={{
        maxHeight: "50vh",
        overflow: "auto",
        padding: "16px",
      }}
    >
      <Typography
        variant="h3"
        style={{ color: "#ED7D31", marginBottom: "2rem" }}
      >
        Roam Warranty, Privacy, and Data Sharing Policy
      </Typography>
      <Typography variant="body1">
        At Roam, we are committed to protecting the privacy and security of our
        customer personal information. This policy explains how we collect, use,
        and share your information, including your contact details, location
        data, and information related to recalls or warranty claims.
      </Typography>
      <Typography
        variant="h5"
        style={{ marginTop: "30px", marginBottom: "5px", fontWeight: "bold" }}
      >
        Information we collect
      </Typography>
      <Typography variant="body1">
        When you purchase a Roam product or use our services, we may collect
        certain personal information from you, including:
      </Typography>
      <Typography variant="body1" component="ul">
        <li>
          Contact information such as your name, email address, phone number,
          and mailing address
        </li>
        <li>Location data from your motorcycle or mobile device</li>
        <li>
          Information about your motorcycle, including its make, model, and year
        </li>
        <li>
          Payment information, such as credit card or other payment information
        </li>
        <li>Information about your preferences and interests</li>
        <li>
          Information related to recalls or safety issues with your product
        </li>
      </Typography>

      <Typography
        variant="h5"
        style={{ marginTop: "30px", marginBottom: "5px", fontWeight: "bold" }}
      >
        Use of information
      </Typography>

      <Typography variant="body1">
        We may use your personal information to:
      </Typography>
      <Typography variant="body1" component="ul">
        <li>
          Process your orders and provide you with our products and services
        </li>
        <li>
          Communicate with you about your orders and our products and services
        </li>
        <li>Improve our products and services</li>
        <li>Customize your experience with our services</li>
        <li>Market our products and services to you</li>
        <li>Comply with legal and regulatory requirements</li>
        <li>Notify you of recalls or safety issues with your motorcycle</li>
        <li>
          Inform you of warranty claims or special offers related to recalls or
          safety issues
        </li>
      </Typography>
      <Typography variant="body1">
        We may also use your location data to provide you with location-based
        services, such as finding the nearest service location or charging
        station.
      </Typography>
      <Typography
        variant="h5"
        style={{ marginTop: "30px", marginBottom: "5px", fontWeight: "bold" }}
      >
        Sharing of information
      </Typography>
      <Typography variant="body1">
        We may share your personal information with:
      </Typography>
      <Typography variant="body1" component="ul">
        <li>
          Service providers who help us operate our business and provide you
          with our products and services
        </li>
        <li>
          Service centers who may provide you with service for your motorcycle
        </li>
        <li>Financial institutions who process payments</li>
        <li>
          Law enforcement or government agencies when required by law or to
          protect our legal rights
        </li>
        <li>Other third parties with your consent</li>
      </Typography>
      <Typography variant="body1">
        We will not sell your personal information to third parties.
      </Typography>
      <Typography
        variant="h5"
        style={{ marginTop: "30px", marginBottom: "5px", fontWeight: "bold" }}
      >
        Security of information
      </Typography>
      <Typography variant="body1">
        We take reasonable measures to protect your personal information from
        unauthorized access, use, or disclosure. We use secure servers and
        encryption to protect your information during transmission.
      </Typography>
      <Typography
        variant="h5"
        style={{ marginTop: "30px", marginBottom: "5px", fontWeight: "bold" }}
      >
        Retention of information
      </Typography>
      <Typography variant="body1">
        We will retain your personal information for as long as necessary to
        fulfill the purposes for which it was collected, unless a longer
        retention period is required by law.
      </Typography>
      <Typography
        variant="h5"
        style={{ marginTop: "30px", marginBottom: "5px", fontWeight: "bold" }}
      >
        Your rights
      </Typography>
      <Typography variant="body1">
        You have the right to access, correct, or delete your personal
        information that we have collected. You can also object to the
        processing of your personal information or request that we restrict the
        use of your information. To exercise your rights, please contact us
        using the contact information provided below.
      </Typography>
      <Typography
        variant="h5"
        style={{ marginTop: "30px", marginBottom: "5px", fontWeight: "bold" }}
      >
        Contact us
      </Typography>
      <Typography variant="body1">
        If you have any questions or concerns about this privacy policy or our
        use of your personal information, please contact us at{" "}
        <Link
          href="mailto:support@roam-electric.com"
          style={{ color: "#ED7D31" }}
        >
          support@roam-electric.com
        </Link>{" "}
        or call <span style={{ color: "#ED7D31" }}>+254112357561</span>.
      </Typography>
      <Typography
        variant="h5"
        style={{ marginTop: "30px", marginBottom: "5px", fontWeight: "bold" }}
      >
        Changes to this policy
      </Typography>
      <Typography variant="body1" style={{ marginBottom: "2rem" }}>
        We may update this privacy policy from time to time. We will notify you
        of any material changes to this policy by posting a notice on our
        website or by sending you an email.
      </Typography>
      <Typography
        variant="h3"
        style={{ color: "#ED7D31", marginBottom: "2rem" }}
        gutterBottom
      >
        Warranty Coverage
      </Typography>
      {/* <Typography variant="h5"
        style={{ marginTop: "30px", marginBottom: "5px", fontWeight: "bold" }} gutterBottom>
        Introduction
      </Typography> */}
      <Typography variant="body1">
        <b>ROAM ELECTRIC LIMITED</b>, a Kenyan limited company P. O BOX
        30117-00100, NAIROBI, KENYA (“<b>Roam</b>” or the “<b>Seller</b>”)
        warrants that, subject to the terms and conditions set out in this
        document, the specific products supplied by <b>Roam</b> (as published in
        our website{" "}
        <Link
          href="http://www.roam-electric.com"
          target="_blank"
          rel="noopener"
          style={{ color: "#ED7D31" }}
        >
          www.roam-electric.com
        </Link>
        ) will be fit for purpose during the applicable warranty period (our
        warranty) the customer (“<b>Buyer</b>”).
      </Typography>

      <Typography
        variant="h5"
        style={{ marginTop: "30px", marginBottom: "5px", fontWeight: "bold" }}
        gutterBottom
      >
        1. Warranty Coverage
      </Typography>
      <Typography variant="body1">
        Except as otherwise agreed by Seller and Buyer in writing, Seller
        warrants that during the warranty period (as defined in Section 2) the
        components of the Products (as listed below) will be free from defects
        in material and workmanship under normal uses (as explained in the
        “Users Manual”), and will conform to Seller’s published Specifications
        for the Product. “Specifications" means the current User’s Manual,
        technical specification or other Product documentation prepared by the
        Seller (excluding marketing collateral). This applies to the following
        items : Electric Motor, Controller, Battery Pack, Frame, Suspension,
        Portable Charger, Cable Harness & Electricals. This warranty is non-
        transferable and applies only to the original Buyer of the Product.
        Seller replacement parts used in component replacement may be new or
        equivalent to new. Seller will provide all parts and services required
        to repair or replace the components without charge for parts or labor,
        provided that the motorcycle is returned to an authorized Manufacturer
        dealer or service center.
      </Typography>
      <Typography
        variant="h5"
        style={{ marginTop: "30px", marginBottom: "5px", fontWeight: "bold" }}
        gutterBottom
      >
        2. Workmanship Warranty
      </Typography>
      <Typography variant="body1">
        Our Workmanship Warranty will apply with respect to the specific
        components supplied. During the Warranty Period, the Seller will, at its
        option, repair or replace any defective part or parts without charge for
        parts or labour. The “Warranty Period” means the period of time
        commencing upon first use of the Product and continuing for a specific
        amount of driving kilometres but up to a specific maximum amount of
        months. The limited warranty covers parts and services for all major
        components as follows: Portable Charger (12 Months); Cable Harness &
        Electricals (12 Months); Electric Motor (12 Months); Controller (12
        Months); Frame (12 Months or 30,000km whichever comes first); Battery
        Pack (12 Months or 800 Cycles at 80% Capacity whichever comes first).
        Seller reserves the right to make changes to its workmanship warranty,
        as communicated in writing to the Buyer; however, such warranty changes
        will only apply to Products purchased after the date of such notice.
      </Typography>
      <Typography
        variant="h5"
        style={{ marginTop: "30px", marginBottom: "5px", fontWeight: "bold" }}
        gutterBottom
      >
        3. Limited Pro-rata Battery Performance Warranty
      </Typography>
      <Typography variant="body1">
        The Battery supplied with the Product is also covered by an additional
        Performance Warranty that is based on lowest of Remaining Cycles or
        Remaining Time Period. The Performance Warranty coverage for the Battery
        is based on the number of cycles or the time elapsed. The Performance
        Warranty lasts until either the Battery has completed 800 cycles or 24
        months from the date of purchase, whichever occurs first.
      </Typography>
      <Typography variant="body1">
        If the battery expires or if performance falls below coverage thresholds
        before the 800 cycles or 24 months, a pro rata coverage will be
        calculated on both cycle-based and time-based, whereas Roam covers the
        lowest replacement value either in the form of Battery Replacement
        Credit or Rental Battery coverage for the duration of the lower amount
        of remaining period or cycles.
      </Typography>
      <Typography variant="body1">
        Cycle-Based Coverage will be calculated as:
        <br />
        Total cost of replacement battery x 1 - (Cycles Completed / 800)
      </Typography>
      <Typography variant="body1">
        Time-based Coverage will be calculated as the greater of:
        <br />
        Total Cost of Replacement Battery × 1 - (Elapsed Months / 24)
      </Typography>
      <Typography variant="body1">
        The Performance Warranty coverage is in addition to and not in lieu of
        the Workmanship Warranty coverage (per Section 2) of 80% performance for
        12 months or 800 cycles, whichever comes first.
      </Typography>
      <Typography variant="body1">
        Roam reserves the discretion to fulfil its warranty obligations through
        the provision of replacement products or repair services or battery
        rental, as deemed appropriate based upon user misuse or mishandling of
        the product. Roam also reserves the right to make changes to its
        Performance Warranty, as communicated in writing to the Buyer; however,
        such changes will only apply to Products purchased after the date of
        such notice.
      </Typography>
      <Typography
        variant="h5"
        style={{ marginTop: "30px", marginBottom: "5px", fontWeight: "bold" }}
        gutterBottom
      >
        4. Exclusions from our Warranty Coverage and Limitations
      </Typography>
      <Typography variant="body1">
        This warranty does not cover routine maintenance, wear and tear, or
        damage resulting from misuse, accident, or improper repair or storage.
        The Company shall not be liable for providing the warranty services if
        it arises from the below:
      </Typography>
      <Typography variant="body1" component="ol">
        <li>
          Damages or defects to the Products arising from transport, rough
          handling, misuse or repair by unauthorized personal, damage by animals
          or by accidents, heat, misuse, fire, flood or acts of god.
        </li>
        <li>
          Any problem arising from voltage fluctuation beyond the range
          mentioned in the user manual/product specification and/or use of
          improper power supply, electrical socket, connection or positioning.
        </li>
        <li>
          If the Product/s is/are maintained and operated not according to the
          owner's manual.
        </li>
        <li>Third party applications used on/or with the Products.</li>
        <li>
          Repairs or exchanges by not authorized dealers for any parts
          excluding: Rear rubber coupling, Side mirrors, Tires, Windshield and
          Cowling, Drive Chain, Knee Guards, Center Stand, Side Stand, Chain
          Guard, Front Fork, Brake Pads ( front and back), Space Tank Lock.
        </li>
      </Typography>
      <Typography variant="body1">
        In no event shall Manufacturer be liable for any incidental or
        consequential damages, including but not limited to loss of use,
        inconvenience, or commercial loss. This warranty is in lieu of all other
        warranties, express or implied, including but not limited to any implied
        warranties of merchantability or fitness for a particular purpose. Roam
        shall not be liable for any damage or injury to persons or property
        arising out of the use or operation of the electric motorcycle, or for
        any incidental or consequential damages resulting from such use or
        operation. This Agreement constitutes the entire agreement between Roam
        and Buyer with respect to the product and supersedes all prior or
        contemporaneous communications and proposals, whether oral or written,
        between the parties. Roam may make any modification or improvement to
        Product in future production at any time without prior notice and
        without any obligation to install the same on Products previously
        dispatched for sale. Parts repaired or replaced under this warranty are
        warrantied only throughout the remainder of the original warranty
        period.
      </Typography>
      <Typography variant="body1">
        This Agreement shall be governed by and construed in accordance with the
        laws of Kenya. Any disputes arising under or in connection with this
        Agreement shall be resolved exclusively by the courts located in Kenya,
        and the parties hereby submit to the personal jurisdiction of such
        courts.
      </Typography>
    </Paper>
  );
}

export default RoamPolicyDocument;
