import React, { useContext, useEffect } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useLocation } from "react-router-dom";
import canopyAPI from "../api/canopyAPI";
import AppContext from "./AppContext";

function DiagnosticsReport() {
  const context = useContext(AppContext);

  const location = useLocation();
  const diagnostics = location.state?.diagnostics;
  const newserialnumber = location.state?.new_serial_number;
  const Primaryserialnumber = location.state?.primaryserialnumber;
  const Secondaryserialnumber = location.state?.secondaryserialnumber.value;

  useEffect(() => {
    canopyAPI.loggerService(
      {
        logs: diagnostics
          ? diagnostics + " - " + Diagnostics_Report[diagnostics]
          : "Invalid diagnostics key",
        severity: diagnostics === "R2" ? "info" : "debug",
      },
      context.token
    );
  }, []);

  const Diagnostics_Report = {
    R1: `Mark as QRC missing,
      Engrave ${Primaryserialnumber} into the QRC,
      check workflow outcome`,
    R2: `Mark as OK,
       Pass`,
    R3: `Mark as QRC fail,
       Destroy QR code,
      engrave ${Primaryserialnumber} in new QRC`,
    R4: `Mark as Invalid SN,
       Destroy QR code,
      new SN for BMS re-flash and new QRC is ${newserialnumber}`,
    R5: `Mark as Missing BMS serial number, 
       Flash BMS with serial number, ${Secondaryserialnumber} `,
    R6: `Mark as Invalid SN,
       Destroy QR code, 
      New SN for BMS re-flash and new QRC is ${newserialnumber}`,
    R7: `Mark as Identity unknown,
       New SN for BMS re-flash and new QRC is ${newserialnumber}`,
  };

  console.log("serial number on the diagnostics side", newserialnumber);
  console.log(
    "primary serial number on the diagnostics side",
    Primaryserialnumber
  );

  return (
    <Box style={{ width: "100%", height: "100%", overflow: "auto" }}>
      <Box m="10px 5px 0 5px" display="flex" justifyContent="space-between">
        <Typography variant="h4" gutterBottom>
          Diagnostics Report
        </Typography>
      </Box>
      <Box sx={{ minWidth: 275 }}>
        <Card variant="outlined">
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              {diagnostics}
              --
              {Diagnostics_Report[diagnostics] || "Invalid diagnostics key"}
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
}

export default DiagnosticsReport;
