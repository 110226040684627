import { Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import React, { useContext, useEffect, useState } from "react";
import Header from "../components/Header";
import { tokens } from "../theme";
import axios from "axios";
import AppContext from "../components/AppContext";

function SalesList() {
  const context = useContext(AppContext);
  const location = localStorage.getItem("location");
  const reseller = localStorage.getItem("reseller");
  const reseller_id = localStorage.getItem("reseller_id");
  const location_id = localStorage.getItem("location_id");

  const [list, setList] = useState([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    const headers = {
      "api-token": context.token,
    };

    const request = {
      reseller: reseller_id,
    };

    axios.post(
      `${process.env.REACT_APP_CANOPY_API_URL}/warranty/getByReseller`,
      request,
      { headers }
    )
    .then((res) => {
      const updatedList = res.data.map(item => ({
        ...item,
        resellerName: item.reseller?.name || 'Unknown Reseller',
        locationName: item.reseller_location?.name || 'Unknown Location',
      }));
      setList(updatedList);
      console.log("res.data", res.data);
      console.log("location, ", location);
      console.log("reseller, ", reseller);
      console.log("reseller_id", reseller_id);
      console.log("location_id", location_id);
    })
    .catch((err) => {
      console.log("Error from Showing PoS List");
    });
  }, [context.token, reseller, reseller_id, location, location_id]);

  const columns = [
    {
      field: "name",
      headerName: "Customer",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    { field: "vin", headerName: "Vin No.", flex: 1 },
    {
      field: "phoneNumber",
      headerName: "Phone",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "numberPlate",
      headerName: "Plate No.",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    // {
    //   field: "resellerName",
    //   headerName: "Reseller",
    //   flex: 1,
    //   cellClassName: "name-column--cell",
    // },
    {
      field: "locationName",
      headerName: "Location",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "batteryOne",
      headerName: "Battery Serial(s)",
      renderCell: ({ row: { batteryOne } }) => {
        return <Typography>{batteryOne}</Typography>;
      },
      flex: 1,
      type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "signDate",
      headerName: "Date",
      flex: 1,
      cellClassName: "name-column--cell",
    },
  ];

  return (
    <Box m="10px 5px 0 5px">
      <Header title={"Sales List Module"} subtitle={"Use filters"} />
      <Box
        height="75vh"
        m="5px 0 0 0"
        sx={{
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.grey[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold",
          },
          "& .MuiDataGrid-main": {
            border: "none",
          },
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            // border: "none",
          },
          "& .name-column--cell": {
            color: colors.grey[100],
          },
          "& .MuiDataGrid-row Mui-selected": {
            maxHeight: "none !important",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.grey[700],
          },
          "& .MuiCheckbox-colorPrimary": {
            color: colors.greenAccent[700],
          },
        }}
      >
        <DataGrid
          columns={columns}
          rows={list}
          getRowId={(row) => row.id} // Use the unique id from the data
          pageSize={10}
          rowsPerPageOptions={[10]}
          checkboxSelection
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
}

export default SalesList;
