import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import canopyAPI from "../api/canopyAPI";
import AppContext from "../components/AppContext";

function BatterySerialNumberDiagnostics() {
  const [messages, setMessages] = useState([]);
  const [diagnosticspage, setDiagnosticspage] = useState([]);
  const [qrcPage, setQrcpage] = useState([]);
  const [primarySerialnumber, setPrimarySerialnumber] = useState({
    value: "CAN Serial number not found",
    regex: true,
    Duplicate: true,
  });
  const navigate = useNavigate();
  // Initialize useNavigate hook
  const [redirect, setRedirect] = useState(false);

  const [newSerialnumber, setnewSerialnumber] = useState({
    new_Serialnumber: "SJV1.1BAK45A3000-0000",
    Duplicate: true,
  });

  const context = useContext(AppContext);

  let sc_token = localStorage.getItem("sc_token");

  if (!sc_token) {
    localStorage.removeItem("sc_token");
    window.location.replace("/");
  }

  useEffect(() => {
    canopyAPI.loggerService(
      {
        logs: "Battery serial number module started...",
        severity: "info",
      },
      sc_token
    );

    if (redirect) {
      // Redirect to QRC Page route
      navigate("/qrc-battery-serialnumber", {
        state: {
          primarySerialnumber: primarySerialnumber.value,
        },
        setDiagnosticspage: setDiagnosticspage, // Pass setDiagnosticspage directly as a prop
      });
      console.log("redirected to QRC page");
    }
  }, [redirect, context.token]); // Run this effect whenever `redirect` state changes

  const handleIssueNewSerialNumber = async (reason) => {
    // const myRegex = /^SJV1\.1BAK45A3\d{3}-\d{4}$/;

    function generateRandomSerial() {
      return Math.floor(Math.random() * 10000)
        .toString()
        .padStart(4, "0");
    }

    // Generate random serial numbers
    const randomSerial1 = Math.floor(Math.random() * 1000)
      .toString()
      .padStart(3, "0"); // Generate a three-digit number for randomSerial1
    const randomSerial2 = generateRandomSerial(); // Generate a four-digit number for randomSerial2

    const myNewSerial = `SJV1.1BAK45A3${randomSerial1}-${randomSerial2}`;

    console.log("BSN Token", sc_token);

    const saveSerial = await canopyAPI.saveNewBatterySerial(
      {
        battery_serial: myNewSerial,
        // batch: "Batch_1",
        // batch_size: "60.0",
        // batch_receiving_date: "2023-01-01 00:00:00",
      },
      context.token || sc_token
    );

    if (context.token || sc_token) {
      canopyAPI.loggerService(
        {
          logs: "New Serial number has been issued by Canopy",
          severity: "debug",
        },
        context.token || sc_token
      );
    }

    // console.log(saveSerial.data);

    if (saveSerial?.status === 200) {
      navigate("/diagnostics-report", {
        state: {
          diagnostics: reason,
          primaryserialnumber: primarySerialnumber,
          secondaryserialnumber: "No QRC",
          new_serial_number: myNewSerial,
        },
      });
    } else {
      console.log("Issued Serial Number already exists, generate a new one");
    }
  };

  function matchesFormat(input) {
    const regex = /^SJV1\.1BAK45A\d{4}-\d{4}$/;
    const isFormatValid = regex.test(input);

    setPrimarySerialnumber((prevState) => ({
      ...prevState,
      regex: isFormatValid,
    }));
    return isFormatValid;
  }

  const handlePrimarySerialnumber = (theprimarySerialnumber) => {
    // logic to handle the primary serial number
    //check the regex first
    console.log(
      "primary serial number before regex",
      theprimarySerialnumber.value
    );
    const regexMatch = matchesFormat(theprimarySerialnumber.value);
    console.log("regex value", regexMatch);
    if (regexMatch) {
      //-------->take the user to the Next page to get the QRserial number
      //pass in the primarySerialnumber to that page as a prop
      setQrcpage("Go to the Next page to input the QR code serial number");
      setRedirect(true);
      console.log("checking redirect");
    } else {
      handleIssueNewSerialNumber("R4");

      console.log(
        "regex fails, meaning primary serial number is not in the correct format"
      );

      //--------->send to diagnostics page and print out R4

      //issue new serial number
      //then check the duplicate
      //if duplicate is true
      //issue new serial number
      //if duplcate is false
      //send user to diagnosticss page with R4
    }
  };

  const handleWebSocketMessage = (message) => {
    // Convert the Blob object to a string
    message.text().then((messageText) => {
      // Update the messages state with the received message
      setMessages((prevMessages) => [...prevMessages, messageText]);
      if (context.token || sc_token) {
        canopyAPI.loggerService(
          {
            logs: `BMS battery serial as read from Websocket (BDT) ${messageText}`,
            severity: "debug",
          },
          context.token || sc_token
        );
      }

      // Update the value of primarySerialnumber based on message content
      const primarySerialNumberValue =
        messageText.trim() !== "" ? messageText : "CAN Serial number not found";
      setPrimarySerialnumber((prevState) => ({
        ...prevState,
        value: primarySerialNumberValue,
      }));

      handlePrimarySerialnumber({
        value: primarySerialNumberValue,
      });
    });
  };

  const handleDiagnosticsPageClick = () => {
    navigate("/diagnostics-report", {
      state: {
        diagnostics: diagnosticspage,
        primaryserialnumber: primarySerialnumber,
        secondaryserialnumber: "No QRC",
        new_serial_number: newSerialnumber?.new_Serialnumber,
      },
    });
    console.log("Diagnostics Page button clicked");
  };

  const handleQRCPageClick = () => {
    // Redirect to QRC Page route
    navigate("/qrc-battery-serialnumber", {
      state: {
        primarySerialnumber: primarySerialnumber.value,
      },
      setDiagnosticspage: setDiagnosticspage, // Pass setDiagnosticspage directly as a prop
    });
    console.log("QRC Page button clicked");
  };

  useEffect(() => {
    const ws = new WebSocket("wss://canopy-api-dev.roam-electric.com");

    ws.onopen = () => {
      console.log("Connected to WebSocket server as a react client");
      // You can send initial data if needed
    };

    ws.onmessage = (event) => {
      console.log("Received message:", event.data);
      handleWebSocketMessage(event.data);
    };

    ws.onclose = () => {
      console.log("WebSocket connection closed");
      // Handle WebSocket closing here
    };
    return () => {
      // Clean up WebSocket connection and clear timeout
      ws.close();
      //clearTimeout(timeout);
    };
  }, []);

  return (
    <Box style={{ width: "100%", height: "100%", overflow: "auto" }}>
      <Box m="10px 5px 0 5px" display="flex" justifyContent={"space-between"}>
        <Header title={"Battery Serial Number Module"} subtitle={"Search"} />
      </Box>

      <Box sx={{ minWidth: 275 }}>
        <Card variant="outlined">
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              {primarySerialnumber.value
                ? primarySerialnumber.value
                : "No battery serial number available"}
            </Typography>
            {diagnosticspage.length > 0 && ( // Conditional rendering for Typography
              <Typography variant="body2" component="p">
                Additional information on the diagnosticspage. Go to the
                diagnostics page
              </Typography>
            )}

            {qrcPage.length > 0 && ( // Conditional rendering for Typography
              <Typography variant="body2" component="p">
                {qrcPage}
              </Typography>
            )}
          </CardContent>
        </Card>
      </Box>

      <Box mt={2} display="flex" justifyContent="center">
        <Button
          variant="contained"
          color="primary"
          style={{
            backgroundColor: "#ED7D31",
            border: "1px solid #dddddd",
            padding: "1rem",
            marginRight: "0.5rem", // Add small margin between buttons
          }}
          endIcon={<NotInterestedIcon />}
          onClick={() => handleDiagnosticsPageClick}
        >
          Diagnostics Page
        </Button>

        <Button
          variant="contained"
          color="primary"
          style={{
            backgroundColor: "#ED7D31",
            border: "1px solid #dddddd",
            padding: "1rem",
            marginLeft: "0.5rem", // Add small margin between buttons
          }}
          endIcon={<SendIcon />}
          onClick={handleQRCPageClick}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
}

export default BatterySerialNumberDiagnostics;

//the CAN serial number comes in
//if the CAN serial number is present we store it in primaryserial number
//check regex,
//if regex is true
//go get the QRC serial number
//---------------------

//if regex is false
//issue new serial number
//then check the duplicate
//if duplicate is true
//issue new serial number
//if duplcate is false
//send user to diagnosticss page with R4
