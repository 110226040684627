import { CssBaseline, ThemeProvider } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import { ColorModeContext, useMode } from "./theme";
import Dashboard from "./scenes/Dashboard";
import SidePanel from "./scenes/global/SidePanel";
// import TopBar from "./scenes/global/TopBar";
import NewSale from "./scenes/NewSale";
import SalesList from "./scenes/SalesList";
import AssetLookup from "./scenes/AssetLookup";
import { useEffect, useState } from "react";
import axios from "axios";
import AppContext from "./components/AppContext";
import RoamHubSupervisor from "./scenes/RoamHubSupervisor";
import BatterySerialNumberDiagnostics from "./scenes/BatterySerialNumberDiagnostics";
import QrcSerialNumberDiagnostics from "./components/QrcSerialNumberDiagnostics";
import DiagnosticsReport from "./components/DiagnosticsReport";
import { salesOrderFilter } from "./utils/helpers/salesOrdersList";
import Login from "./scenes/Login";

document.body.style.fontFamily = "Poppins, sans-serif";

function App() {
  let sc_token = localStorage.getItem("sc_token");

  const [theme, colorMode] = useMode();
  const [reseller, setReseller] = useState();
  const [outlet, setOutlet] = useState();
  const [token, setToken] = useState(sc_token);

  // useEffect(() => {
  //   myToken();
  // }, []);

  const myToken = async () => {
    const data = JSON.stringify({
      secret: process.env.REACT_APP_SECRET,
      key: process.env.REACT_APP_KEY,
      grant_type: process.env.REACT_APP_GRANT_TYPE,
    });

    const apiToken = await axios({
      method: "post",
      url: `${process.env.REACT_APP_CANOPY_API_URL}/getToken`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    }).catch((error) => {
      return error.response;
    });

    if (apiToken) {
      setToken(apiToken.data);
      localStorage.setItem("sc_token", apiToken.data);
      await getUser(apiToken?.data);
    }
  };

  const getAllResellers = async (ordersFilter, token) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_CANOPY_API_URL}/reseller/all`,
        {
          headers: {
            "api-token": token,
          },
        }
      );
      const resellerDetails = response?.data?.find(
        (reseller) => reseller.name === ordersFilter
      );
      localStorage.setItem("reseller_id", resellerDetails?.id);
    } catch {
      // Do nothing for now
    }
  };

  const getUser = async (token) => {
    try {
      const res = await axios.get(`/oauth2/userinfo`);
      if (res) {
        let mail = res?.data?.email;
        const resellerEmailDomain = mail?.split("@");
        const _ordersFilter = salesOrderFilter(resellerEmailDomain[1]);
        await getAllResellers(_ordersFilter, token);
        localStorage.setItem("userName", mail.split(/[.@]/)[0]);
      } else {
        console.log("Couldn't fetch auth data");
        let mail = "antony.m.munyao@roam-elecric.com";
        localStorage.setItem("userName", mail.split(/[.@]/)[0]);
      }
    } catch (error) {
      let mail = "user@invalid.com";
      localStorage.setItem("userName", mail.split(/[.@]/)[0]);
      // console.log(error);
    }
  };

  return (
    <AppContext.Provider
      value={{
        setReseller,
        reseller,
        setOutlet,
        outlet,
        token,
        setToken,
        myToken,
      }}
    >
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="app">
            {token && <SidePanel />}
            <main className="content">
              {/* <TopBar /> */}
              <Routes>
                <Route exact path="/" element={<Login />} />{" "}
                {/* To be changed back to point to Dashboard once SSO is fixed */}
                <Route path="/new-sale" element={<NewSale />} />
                <Route path="/sales-list" element={<SalesList />} />
                <Route path="/asset-lookup" element={<AssetLookup />} />
                <Route
                  path="/roamhub-supervisor"
                  element={<RoamHubSupervisor />}
                />
                <Route
                  path="/battery-serialnumber-diagnostics"
                  element={<BatterySerialNumberDiagnostics />}
                />
                <Route
                  path="/qrc-battery-serialnumber"
                  element={<QrcSerialNumberDiagnostics />}
                />
                <Route
                  path="/diagnostics-report"
                  element={<DiagnosticsReport />}
                />
                {/* <Route
                  path="/"
                  element={() => {
                    window.location.href =
                      "https://canopy.roam-electric.com/oauth2/sign_out";
                    return;
                  }}
                /> */}
              </Routes>
            </main>
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </AppContext.Provider>
  );
}

export default App;
