import axios from "axios";

// Create user
const createUser = async (values, token) => {
  return await axios({
    method: "post",
    url: `${process.env.REACT_APP_CANOPY_API_URL}/wlt/createUser`,
    headers: {
      "api-token": token,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(values),
  }).catch((error) => {
    return error.response;
  });
};

// Create group
const createGroup = async (values, token) => {
  return await axios({
    method: "post",
    url: `${process.env.REACT_APP_CANOPY_API_URL}/wlt/createGroup`,
    headers: {
      "api-token": token,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(values),
  }).catch((error) => {
    return error.response;
  });
};

// Assign user to group
const assignUserToGroup = async (values, token) => {
  return await axios({
    method: "post",
    url: `${process.env.REACT_APP_CANOPY_API_URL}/wlt/assignUserToGroup`,
    headers: {
      "api-token": token,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(values),
  }).catch((error) => {
    return error.response;
  });
};

// Assign asset to group
const assignAssetToGroup = async (values, token) => {
  return await axios({
    method: "post",
    url: `${process.env.REACT_APP_CANOPY_API_URL}/wlt/assignAssetToGroup`,
    headers: {
      "api-token": token,
      "Content-Type": "application/json",
    },
    data: JSON.stringify([values]),
  }).catch((error) => {
    return error.response;
  });
};

// Verify asset url
const verifyAssetUrl = async (values, token) => {
  return await axios({
    method: "post",
    url: `${process.env.REACT_APP_CANOPY_API_URL}/wlt/verifyAssetUrl`,
    headers: {
      "api-token": token,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(values),
  }).catch((error) => {
    // return error.response;
  });
};

// Verify asset url
const checkDuplicates = async (values, token) => {
  return await axios({
    method: "post",
    url: `${process.env.REACT_APP_CANOPY_API_URL}/wlt/checkDuplicates`,
    headers: {
      "api-token": token,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(values),
  }).catch((error) => {
    return error.response;
  });
};

// Update an asset with extra details
const updateAsset = async (values, token) => {
  return await axios({
    method: "put",
    url: `${process.env.REACT_APP_CANOPY_API_URL}/wlt/updateAsset`,
    headers: {
      "api-token": token,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(values),
  }).catch((error) => {
    return error.response;
  });
};

// Vin Lookup
const vinLookup = async (values, token) => {
  return await axios({
    method: "post",
    url: `${process.env.REACT_APP_CANOPY_API_URL}/lookupAssetByVin`,
    headers: {
      "api-token": token,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ vin: values }),
  }).catch((error) => {
    //return error.response;
  });
};

// Vin Registered?
const vinRegistered = async (value, token) => {
  return await axios({
    method: "post",
    url: `${process.env.REACT_APP_CANOPY_API_URL}/warranty/getWarranty`,
    headers: {
      "api-token": token,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(value),
  }).catch((error) => {
    //return error.response;
  });
};

const saveNewBatterySerial = async (values, token) => {
  return await axios({
    method: "post",
    url: `${process.env.REACT_APP_CANOPY_API_URL}/battery/saveNew`,
    headers: {
      "api-token": token,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(values),
  }).catch((error) => {
    //return error.response;
  });
};

const loggerService = async (values, token) => {
  console.log(values);
  return await axios({
    method: "post",
    url: `${process.env.REACT_APP_CANOPY_API_URL}/logs/saveLogs`,
    headers: {
      "api-token": token,
      "Content-Type": "application/json",
    },
    data: values,
  }).catch((error) => {
    //return error.response;
  });
};

const resellers = async (token) => {
  return await axios({
    method: "get",
    url: `${process.env.REACT_APP_CANOPY_API_URL}/reseller/all`,
    headers: {
      "api-token": token,
      "Content-Type": "application/json",
    },
  }).catch((error) => {
    //return error.response;
  });
};

const locations = async (values, token) => {
  console.log("values from locations", values);
  return await axios({
    method: "post",
    url: `${process.env.REACT_APP_CANOPY_API_URL}/reseller/findAllLocationsUnderReseller`,
    headers: {
      "api-token": token,
      "Content-Type": "application/json",
    },
    data: values,
  }).catch((error) => {
    //return error.response;
  });
};

const controlBeacon = async (payload, token) => {
  return await axios({
    method: "post",
    url: `${process.env.REACT_APP_CANOPY_API_URL}/canopy-troca/controlBeacon`,
    headers: {
      "api-token": token,
      "Content-Type": "application/json",
    },
    data: payload,
  }).catch((error) => {
    return error.response;
  });
};
// eslint-disable-next-line
export default {
  createUser,
  createGroup,
  assignUserToGroup,
  assignAssetToGroup,
  verifyAssetUrl,
  checkDuplicates,
  updateAsset,
  vinLookup,
  vinRegistered,
  saveNewBatterySerial,
  loggerService,
  resellers,
  locations,
  controlBeacon,
};
