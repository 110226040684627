import React from "react";
import { Button, Box, Grid, TextField } from "@mui/material";
import SignatureCanvas from "react-signature-canvas";
import { CloudDoneOutlined } from "@mui/icons-material";

function SignaturePad({ onChange, value }) {
  const signatureRef = React.createRef();

  const handleClear = () => {
    signatureRef.current.clear();
    onChange("");
  };

  const handleSave = () => {
    if (!signatureRef.current.isEmpty()) {
      const dataUrl = signatureRef.current.toDataURL();
      onChange(dataUrl);
    }
  };

  return (
    <Box>
      <Box
        border="1px solid grey"
        borderRadius="3px"
        overflow="hidden"
        position="relative"
        justifySelf={"center"}
      >
        <SignatureCanvas
          ref={signatureRef}
          penColor="black"
          canvasProps={{
            width: 1200,
            height: 200,
            className: "sigCanvas",
          }}
        />
      </Box>

      <Box>
        <Grid spacing={2} sx={{ display: "flex", flexDirection: "row" }}>
          <Button
            onClick={handleClear}
            variant="contained"
            color="primary"
            style={{
              backgroundColor: "white",
              border: "1px solid #ED7D31",
              color: "#ED7D31",
              margin: "1rem",
            }}
          >
            Clear entry to try again
          </Button>

          <Button
            onClick={handleSave}
            variant="contained"
            color="primary"
            style={{
              backgroundColor: "white",
              border: "1px solid #ED7D31",
              color: "#ED7D31",
              marginBottom: "1rem",
              marginTop: "1rem",
            }}
          >
            <CloudDoneOutlined style={{ marginRight: "3px" }} /> Upload
            signature
          </Button>

          <TextField
            // disabled
            style={{
              margin: "1rem",
              flex: 1,
            }}
            id="signature"
            name="signature"
            label="Once uploaded, the URL of the customer's signature will appear here *"
            placeholder="The URL of a successful upload will appear here, do not enter anything here"
            // fullWidth
            value={value}
            InputProps={{
              style: { color: "#211616", overflow: "hidden" },
              readOnly: true, // Make the input uneditable
            }}
          />
        </Grid>
      </Box>
    </Box>
  );
}

export default SignaturePad;
