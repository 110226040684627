import {
  Box,
  Grid,
  Typography,
  TextField,
  Checkbox,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import Alert from "@mui/material/Alert";
import React, { useEffect, useState } from "react";
import moment from "moment";
import axios from "axios";
import canopyAPI from "../api/canopyAPI";
import { useFormik } from "formik";
import { sendOTPContacts } from "../api/mtejaAPI";
import * as Yup from "yup";
import RoamPolicyDocument from "./RoamPolicyDocument";
import SignaturePad from "./SignaturePad";
import { PDFDocument } from "pdf-lib";
import warrantyURL from '../assets/Roam_Warranty_Data_Sharing_Policy.pdf'
import { formartSerialNumbers } from "../utils/helpers/CreateSales";

const SalesForm = ({ location, reseller, token }) => {
  let flagSale = false;

  const ValidationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Please enter the customer's name")
      .min(6, "Please enter a name that is at least 10 characters")
      .max(50, "The name cannot be more than 50 characters")
      .matches(
        /^[a-zA-Z\s]+$/,
        "Name cannot contain numbers or special characters"
      )
      .label("name"),
    phoneNumber: Yup.string()
      .required("Please enter a valid phone number")
      .matches(
        /^(\+?\d{1,3}[-.]?)?(\(\d{3}\)|\d{3})[-.]?\d{3}[-.]?\d{3}$|^\d{10}$|^\(\d{3}\)-\d{3}-\d{3}-\d{3}$/,
        "Invalid phone number format"
      )
      .min(9, "Phone number should be at least 10 digits")
      .label("Phone number"),
    numberPlate: Yup.string()
      .required(
        "Please enter the vehicle's number plate of the motorcycle you are selling"
      )
      .matches(
        /^[A-Za-z0-9\s]+$/,
        "Vehicle number plate should only contain letters, numbers, and spaces"
      )
      .max(9, "Vehicle number plate should have at most 9 characters"),
    vin: Yup.string()
      .required(
        "Please enter the vehicle identification number for the motorcycle you are selling"
      )
      .matches(
        /^[A-Za-z0-9\s]+$/,
        "VIN should only contain letters and numbers"
      )
      .min(17, "A VIN should be exactly 17 characters")
      .max(17, "A VIN should be exactly 17 characters")
      .label("Vin"),
    batteryOne: Yup.string()
      .required("Serial number for the first battery is required")
      .min(12, "Serial number must be at least 12 characters long")
      .test("unique", "Serial number must be unique", function (value) {
        return (
          !value ||
          (value !== this.parent.batteryTwo &&
            value !== this.parent.batteryThree)
        );
      })
      .matches(
        /^[A-Za-z0-9_-]*$/,
        "Serial number should only contain letters, numbers and hyphens"
      )
      .label("Serial number for the first battery"),

    batteryTwo: Yup.string()
      .when("batteryOne", (batteryOneValue, schema) => {
        return batteryOneValue
          ? schema
              .notOneOf(
                [batteryOneValue],
                "Serial number for the first battery and Serial number for the second battery cannot be identical"
              )
              .min(12, "Serial number must be at least 12 characters long")
          : schema;
      })
      .test("unique", "Serial number must be unique", function (value) {
        if (!value) {
          return true;
        }
        return (
          value !== this.parent.batteryOne && value !== this.parent.batteryThree
        );
      })
      .matches(
        /^[A-Za-z0-9_-]*$/,
        "Serial number should only contain letters, numbers and hyphens"
      )
      .label("Battery Serial number 2"),

    batteryThree: Yup.string()
      .when(["batteryOne", "batteryTwo"], (values, schema) => {
        const [batteryOneValue, batteryTwoValue] = values;
        return batteryOneValue && batteryTwoValue
          ? schema
              .notOneOf(
                [batteryOneValue, batteryTwoValue],
                "Serial number for the third battery cannot be identical to Serial number for the first battery or the Serial Number for the second battery"
              )
              .min(12, "Serial number must be at least 12 characters long")
          : schema;
      })
      .test("unique", "Serial number must be unique", function (value) {
        if (!value) {
          return true;
        }
        return (
          value !== this.parent.batteryOne && value !== this.parent.batteryTwo
        );
      })
      .matches(
        /^[A-Za-z0-9_-]*$/,
        "Serial number should only contain letters, numbers and hyphens"
      )
      .label("Battery Serial number 3"),
    email: Yup.string()
      .email("Please enter a valid email address")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Please enter a valid email address"
      )
      .nullable(null)
      .label("Email address"),
    address: Yup.string().min(5).label("address"), //removed .required()
    signature: Yup.string()
      .required("Customer'signature is required")
      .min(2)
      .label("Signature"),
    acceptTerms: Yup.boolean()
      .required(function (value) {
        return `Please ensure the customer accepts Warranty, Privacy, and Data Sharing Policy terms`;
      })
      .oneOf([true], function (value) {
        return `Please ensure the customer accepts Warranty, Privacy, and Data Sharing Policy terms`;
      })
      .label("Terms and Conditions"),
  });

  const [submitErrorMessage, setSubmitErrorMessage] = useState("");
  const [apiErrorMessage, setApiErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  // const [currentDate, setCurrentDate] = useState(moment().format("DD/MM/YYYY"));
  const [showAlert, setShowAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const otp = Math.floor(1000 + Math.random() * 9000);

  const handleSuccessClose = () => {
    setShowSuccessDialog(false);
    setSuccessMessage("");
    setIsSubmitting(false);
  };

  const fillWarrantyPdf = async (formValues) => {
    const date = new Date().toLocaleDateString();
    const serialNumbers = formartSerialNumbers(formValues?.batteryOne, formValues?.batteryTwo, formValues?.batteryThree);
    try {
        // Step 1: Load the PDF form.
        const response = await fetch(warrantyURL, { cache: 'no-store' });
        if (!response.ok) {
            throw new Error(`Failed to fetch PDF: ${response.statusText}`);
        }
        const formPdfBytes = await response.arrayBuffer();

        const pdfDoc = await PDFDocument.load(formPdfBytes);

        // Step 2: Retrieve the form fields.
        const form = pdfDoc.getForm();
        const fields = form.getFields();

        try {
            // Step 3: Set values for the form fields.
            fields[0].setText('Signed Electronically');
            fields[1].setText(`${formValues?.name}`);
            fields[2].setText(`${formValues?.phoneNumber}`);
            fields[3].setText(`${formValues?.vin}`);
            fields[4].setText(serialNumbers);
            fields[5].setText(date);
            fields[6].setText(location ? `${location?.name}` : '');
            form.flatten();
        } catch (error) {
            // Do nothing for now
        }

        // Step 4: Save the modified PDF.
        const pdfBytes = await pdfDoc.save();

        // Step 5: Create a `Blob` from the PDF bytes
        const blob = new Blob([pdfBytes], { type: 'application/pdf' });

        // Step 6: Upload the PDF to your server
        const formData = new FormData();
        formData.append('file', blob, `warranty_${formValues?.vin}.pdf`);

        const uploadResponse = await axios.post(`${process.env.REACT_APP_CANOPY_API_URL}/warranty/uploadWarranty`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        const publicUrl = uploadResponse.data.url;

        return publicUrl; // Return the public URL to be used in the SMS

    } catch (error) {
       // Do nothing for now
    }
};

  
  var dateString = new Date();
  var dateObj = new Date(dateString).toLocaleString("en-US", {
    timeZone: "Africa/Nairobi",
  });

  const [pointofsale, setPointOfSale] = useState({
    name: "",
    phoneNumber: "",
    vin: "",
    numberPlate: "",
    batteryOne: "",
    batteryTwo: "",
    batteryThree: "",
    email: "",
    address: "",
    signature: "",
    signDate: dateObj,
    acceptTerms: false,
    acceptDataPolicy: false,
    flagged: false,
  });

  const formik = useFormik({
    initialValues: pointofsale,
    validationSchema: ValidationSchema,
    onSubmit: async (values) => {
      setIsSubmitting(true);
      setSubmitErrorMessage(false);

      // Check if email is provided, if not, generate it
      if (!values.email) {
        const generatedEmail = `pos+${values.phoneNumber}@roam-electric.com`;
        values.email = generatedEmail;
      }
      try {
        createSale(values);
      } catch (error) {
        console.error(error); // Handle the error
      }
    },
  });

  const cleanPhoneNumber = (phoneNumber) => {
    return phoneNumber.replace(/\D/g, ""); // Removes all non-numeric characters
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await formik.setFieldValue(
        "phoneNumber",
        cleanPhoneNumber(formik.values.phoneNumber)
      );
      // getUserLocation(); // Fetch the user's location inadvertently
      formik.handleSubmit();
    } catch (error) {
      console.log(error);
    } finally {
      // setIsSubmitting(false);
    }
  };

  const pingDbServer = async () => {
    try {
      const headers = {
        "api-token": token,
      };
      const testServerConnection = await axios.get(
        `${process.env.REACT_APP_CANOPY_API_URL}/warranty/test`,
        { headers }
      );
      if (testServerConnection && testServerConnection.status === 200) {
        return true;
      } else {
        console.log("Database Server is down!");
        return false;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const submitForm = async (values, flag) => {
    values.flagged = flag;
    values.reseller = reseller;
    values.reseller_location = location?.id;
    console.log(values, reseller, location, 'the form values')

    setIsLoading(true); // Set loading state to true

    try {
      console.log("FORM DATA is ", values);
      const headers = {
        "api-token": token,
      };
      const formresponse = await axios.post(
        `${process.env.REACT_APP_CANOPY_API_URL}/warranty/newWarranty`,
        values,
        { headers }
      );
      console.log("formresponse status", formresponse.status);
      if (formresponse && formresponse.status === 200) {
        sendWarranty(values);
        return true;
      } else {
        setIsSubmitting(false);
        setApiErrorMessage(
          "Database service is currently unavailable. Please try again later."
        );
        return false;
      }
    } catch (error) {
      setIsSubmitting(false);
      console.error("Error occurred:", error);
      setApiErrorMessage("Database service is currently unavailable. Please try again later.");
      return false;
    } finally {
      setIsLoading(false); // Set loading state to false
    }
  };
  // CREATE SALE FUNCTION
  const createSale = async (formValues) => {    
    setShowAlert(true);
    try {
      console.log("STEP 1: Checking server status");
      const isServerUp = await pingDbServer();

      if (isServerUp) {
        console.log("SERVER is up!");
        console.log("STEP 2: Validating vin");
        // const isValidVin = await validateVin(formik.values.vin);
        const isValidVin = true; //bypass checking vin

        if (isValidVin === true) {
          console.log("VIN passed! (temporarily bypassing this check!)");
          console.log("STEP 3: Creating sale");

          const onboardCustomerToApp = await onBoardCustomer(formValues);
          console.log("ONBOARD STATUS ", onboardCustomerToApp);
          if (onboardCustomerToApp === false) {
            flagSale = true;
          } else {
            flagSale = false;
          }

          const formSubmission = await submitForm(formValues, flagSale);
          if (formSubmission) {
            setPointOfSale({
              name: "",
              phoneNumber: "",
              vin: "",
              numberPlate: "",
              batteryOne: "",
              batteryTwo: "",
              batteryThree: "",
              email: "",
              address: "",
              signature: "",
              signDate: dateObj,
              acceptTerms: false,
              acceptDataPolicy: false,
            });

            setSuccessMessage("Sale recorded successfully");
            setShowSuccessDialog(true); // Show the success message dialog
            formik.resetForm(); // Clear formik form values
            setIsSubmitting(false);
          } else {
            setIsSubmitting(false);
            setSubmitErrorMessage(
              "There was an error saving this sale, please try again"
            );
          }
        } else {
          setIsSubmitting(false);
          setSubmitErrorMessage(
            "The VIN number you've entered is not available for sale. Please verify and try again"
          );
        }
      } else {
        setIsSubmitting(false);
        setSubmitErrorMessage(
          "Service not available right now, please retry in 30s"
        );
      }
    } catch (error) {}
  };

  // USER ONBOARDING FUNCTION
  const onBoardCustomer = async (formValues) => {
    try {
      const isValidVin = await validateAssetProvision(formik.values.vin);
      console.log("STEP 2.1: Validating asset on WLT");
      if (isValidVin === true) {
        console.log("Asset provisioned");
        console.log("STEP 2.2: Creating user");

        const response = await canopyAPI.createUser(
          {
            VName: formik.values.name,
            VMobile: formik.values.phoneNumber,
            VEmail: formik.values.email,
            Password: otp.toString(),
            IParent: "RGWcWw==",
            UserType: "3",
            TimeZoneID: "E. Africa Standard Time",
            roleId: "RGWkAg==",
          },
          token
        );

        if (response.data && response.status === 200) {
          console.log("USER created!");
          console.log("STEP 2.3: Creating user group");

          // assignUserToGroup(response, formValues, otp);
          const groupData = {
            iParent: "RGWcWw==",
            iParentGMID: "RGXC/g==",
            vpkGroupName: formik.values.phoneNumber,
            iCreatedBy: "POS form",
          };

          const newGroupResponse = await canopyAPI.createGroup(
            groupData,
            token
          );

          if (newGroupResponse.data && newGroupResponse.status === 200) {
            console.log("GROUP created!");
            console.log("STEP 2.4: Assigning user to the group");

            const userGroupData = {
              ClientId: "RGWcWw==",
              ipkGroupMID: newGroupResponse.data.groupID,
              UserIds: [response.data.UserId],
            };

            const assignUserToGroupResponse = await canopyAPI.assignUserToGroup(
              userGroupData,
              token
            );

            if (
              assignUserToGroupResponse.data &&
              assignUserToGroupResponse.status === 200
            ) {
              console.log("User assigned to a group");
              console.log("STEP 2.5: Assigning Asset to the group");
              // verifyAndAssignAssetToGroup(newGroupResponse, formValues);

              const verifyAssetUrlResponse = await canopyAPI.verifyAssetUrl(
                {
                  VName: formik.values.vin,
                },
                token
              );

              if (
                verifyAssetUrlResponse.data &&
                verifyAssetUrlResponse.data.Asset.length > 0
              ) {
                const assetId = verifyAssetUrlResponse.data.Asset[0].AssetId;
                // assignAssetToGroup(assetId, newGroupResponse, formValues);
                const assetGroupData = {
                  iParent: "RGWcWw==",
                  ifkAssetId: assetId,
                  iParentGMID: "RGXC/g==",
                  ifkGroupMID: newGroupResponse.data.groupID,
                  IsDriver: false,
                };
                const assignAssetToGroupResponse =
                  await canopyAPI.assignAssetToGroup(assetGroupData, token);
                if (assignAssetToGroupResponse.status === 200) {
                  console.log("Asset assigned to a group");
                  console.log(
                    "STEP 2.6: Updating asset user information Name, phone etc"
                  );

                  // const verifyAsset = verifyAndUpdateAsset(formValues, assetId);
                  const assetGroupData = {
                    ClientID: "RGWcWw==",
                    assetid: assetId,
                    Vname: formValues.vin,
                    owner_name: formValues.name,
                  };

                  const verifyAssetResponse = await canopyAPI.updateAsset(
                    assetGroupData,
                    token
                  );
                  if (verifyAssetResponse.status === 200) {
                    console.log(
                      "***********Onboarding user was successful***********"
                    );
                    sendOTP();
                    return true;
                  } else {
                    console.log(
                      "Flagged: Failed updating asset with new owner details on wlt"
                    );
                    return false;
                  }
                } else {
                  console.log(
                    "Flagged: Failed assigning asset to a group on wlt"
                  );
                  return false;
                }
              } else {
                console.log("Flagged: Asset already assigned to user on wlt");
                return false;
              }
            } else {
              console.log("Flagged: Failed assigning user to group on wlt");
              return false;
            }
          } else {
            console.log("Flagged: Failed creating group on wlt");
            return false;
          }
        } else {
          console.log("Flagged: Failed creating user on wlt");
          return false;
        }
      } else {
        console.log("Flagged: Asset vIN not found on wlt");
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  const sendWarranty = async (formValues) => {
    try {
        const supportNumber = "+254112357561";
        const playStoreLink = "https://shorturl.at/bAFQZ";
        const otp = "123456"; // Just a placeholder for demonstration

        // Await the result of fillWarrantyPdf to get the public URL
        let warrantyURL = await fillWarrantyPdf(formValues);

        const message = `Thank you for choosing the Roam Air!\n\nHere is a copy of your warranty: ${warrantyURL}.\n\nYou can also improve your experience with our easy-to-use mobile app.\nDownload it from the Play Store at ${playStoreLink}.\n\nNeed help? Call our friendly customer support at ${supportNumber}.\n\nWe're here to make your ride with us a breeze!`;

        const payload = {
            appId: process.env.REACT_APP_SMSAPPID,
            from: "ROAMLTD",
            to: String(formValues.phoneNumber),
            text: message,
            tags: ["software-api"],
            referenceId: "",
            requestId: process.env.REACT_APP_REQUESTID,
        };

        const response = await sendOTPContacts(payload, token);

        if (response.status === 200) {
            console.log("- Warranty SMS sent successfully");
        } else {
            console.log("Failed to send Warranty SMS");
        }
    } catch (error) {
        console.error("Error sending warranty SMS:", error.message);
    }
};


  const sendOTP = async () => {
    try {
      const supportNumber = "+254112357561";
      const playStoreLink =
        "https://play.google.com/store/apps/details?id=com.roammotors";

      const payload = {
        appId: process.env.REACT_APP_SMSAPPID,
        from: "ROAMLTD",
        to: String(formik.values.phoneNumber),
        text: `Thank you for choosing the Roam Air! You can improve your experience with our easy-to-use mobile app. Download it at ${playStoreLink} and use this password ${otp} to manage your Roam Air on the app. \nNeed help? Call our friendly customer support at ${supportNumber}. We're here to make your ride with us a breeze!`,
        tags: ["software-api"],
        referenceId: "",
        requestId: process.env.REACT_APP_REQUESTID,
      };

      const response = await sendOTPContacts(payload, token);

      if (response.status === 200) {
        console.log("- OTP SMS sent successfully");
      } else {
        console.log("Failed to send OTP SMS");
      }
    } catch (error) {
      console.error("Error sending contact data:", error.message);
    }
  };

  const validateAssetProvision = async (value) => {
    try {
      const response = await canopyAPI.verifyAssetUrl({ VName: value }, token);

      if (response.data && response.status <= 299) {
        if (
          response.data["Asset"].length === 0 ||
          response.data["Asset"].length === undefined
        ) {
          return false;
        } else if (
          response.data["Asset"].length === 1 &&
          response.data.Asset[0].OwnerId === ""
        ) {
          const duplicateCheckResponse = await canopyAPI.checkDuplicates(
            {
              AssetId: response.data["Asset"][0].AssetId,
            },
            token
          );
          if (
            duplicateCheckResponse.status <= 299 &&
            duplicateCheckResponse.data.Groups[1] === undefined
          ) {
            // console.log("Asset is not yet assigned to a group");
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  //check vin in canopy data dump, if available check if registered
  // const validateVin = async (value) => {
  //   try {
  //     const vinLookup = await canopyAPI.vinLookup(value, token);

  //     if (vinLookup.status === 200) {
  //       const vinRegistered = await canopyAPI.vinRegistered(
  //         { vin: value },
  //         token
  //       );
  //       if (vinRegistered.status === 201) {
  //         return true;
  //       } else {
  //         return false;
  //       }
  //     } else {
  //       return false;
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  return (
    <Box style={{ display: "flex", height: "100vh", marginTop: "10px" }}>
      <Box style={{ flex: "10 0 auto", width: "auto" }} />
      <Box style={{ flex: "auto", width: "auto", overflowY: "scroll" }}>
        <Grid container>
          <Grid item xs={12} mb={"50px"}>
            <Box
              style={{
                marginLeft: "10px",
                marginRight: "10px",
                // maxWidth: "970px",
              }}
            >
              {/* <TermsSwitch /> */}
              <form onSubmit={handleSubmit}>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={6} marginTop="1rem">
                    <TextField
                      id="name"
                      name="name"
                      label="Enter the customer's full name *"
                      placeholder="Full name of customer"
                      fullWidth
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                      InputProps={{
                        style: { color: "#211616", overflow: "hidden" },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} marginTop="1rem">
                    <TextField
                      id="phoneNumber"
                      name="phoneNumber"
                      label="Enter the customer's phone number *"
                      placeholder="Phone number of customer"
                      fullWidth
                      type="text"
                      value={formik.values.phoneNumber}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.phoneNumber &&
                        Boolean(formik.errors.phoneNumber)
                      }
                      helperText={
                        formik.touched.phoneNumber && formik.errors.phoneNumber
                      }
                      InputProps={{
                        style: { color: "#211616", overflow: "hidden" },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} marginBottom="1rem">
                    <TextField
                      id="email"
                      name="email"
                      label="Enter the customer's email"
                      placeholder="Email address of the customer"
                      fullWidth
                      type="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                      InputProps={{
                        style: { color: "#211616", overflow: "hidden" },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} marginBottom="1rem">
                    <TextField
                      id="vin"
                      name="vin"
                      label="Enter the vehicle identification number *"
                      placeholder="Vehicle identification number *"
                      fullWidth
                      type="text"
                      value={formik.values.vin}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.vin && Boolean(formik.errors.vin)}
                      helperText={formik.touched.vin && formik.errors.vin}
                      InputProps={{
                        style: { color: "#211616", overflow: "hidden" },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} marginBottom="1rem">
                    <TextField
                      id="numberPlate"
                      name="numberPlate"
                      label="Enter the vehicle's number plate *"
                      placeholder="Vehicle number plate"
                      fullWidth
                      value={formik.values.numberPlate}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.numberPlate &&
                        Boolean(formik.errors.numberPlate)
                      }
                      helperText={
                        formik.touched.numberPlate && formik.errors.numberPlate
                      }
                      InputProps={{
                        style: { color: "#211616", overflow: "hidden" },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} marginBottom="1rem">
                    <TextField
                      id="batteryOne"
                      name="batteryOne"
                      label="Enter the serial number for the first battery *"
                      placeholder="First battery serial number"
                      fullWidth
                      value={formik.values.batteryOne}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.batteryOne &&
                        Boolean(formik.errors.batteryOne)
                      }
                      helperText={
                        formik.touched.batteryOne && formik.errors.batteryOne
                      }
                      InputProps={{
                        style: {
                          color: "#211616",
                          overflow: "hidden",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} marginBottom="1rem">
                    <TextField
                      id="batteryTwo"
                      name="batteryTwo"
                      label="Enter the serial number for a second battery (optional)"
                      placeholder="Second battery serial number"
                      fullWidth
                      value={formik.values.batteryTwo}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.batteryTwo &&
                        Boolean(formik.errors.batteryTwo)
                      }
                      helperText={
                        formik.touched.batteryTwo && formik.errors.batteryTwo
                      }
                      InputProps={{
                        style: { color: "#211616", overflow: "hidden" },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} marginBottom="1rem">
                    <TextField
                      id="batteryThree"
                      name="batteryThree"
                      label="Enter the serial number for a third battery (optional)"
                      placeholder="Third battery serial number"
                      fullWidth
                      value={formik.values.batteryThree}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.batteryThree &&
                        Boolean(formik.errors.batteryThree)
                      }
                      helperText={
                        formik.touched.batteryThree &&
                        formik.errors.batteryThree
                      }
                      InputProps={{
                        style: { color: "#211616", overflow: "hidden" },
                      }}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    marginBottom="1rem"
                    style={{ display: "none" }}
                  >
                    <TextField
                      id="address"
                      name="address"
                      label="Enter your address *"
                      placeholder="Address"
                      fullWidth
                      value={location}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.address && Boolean(formik.errors.address)
                      }
                      helperText={
                        formik.touched.address && formik.errors.address
                      }
                      InputProps={{
                        style: { color: "#211616", overflow: "hidden" },
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    marginBottom="1rem"
                    style={{ display: "none" }}
                  >
                    <TextField
                      id="signDate"
                      name="signDate"
                      label="Current date"
                      placeholder="Today's Date"
                      fullWidth
                      type="date"
                      InputProps={{
                        readOnly: true,
                        style: { color: "#211616", overflow: "hidden" },
                      }}
                      value={moment().format("YYYY-MM-DD")}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12} marginBottom="1rem">
                    {reseller === "Roam" || reseller === "Ridewell" ? (
                      <RoamPolicyDocument />
                    ) : (
                      <RoamPolicyDocument />
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Checkbox
                      id="acceptTerms"
                      name="acceptTerms"
                      checked={formik.values.acceptTerms}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      color="primary"
                      style={{
                        color: "#ED7D31",
                      }}
                    />
                    <label htmlFor="acceptTerms" style={{ color: "#211616" }}>
                      The customer agrees to this Warranty, Privacy, and Data
                      Sharing Policy *
                    </label>
                    {formik.errors.acceptTerms && (
                      <Typography color="error" className="text-danger">
                        {formik.errors.acceptTerms}
                      </Typography>
                    )}
                  </Grid>

                  <Grid item md={12} xs={12} sm={12}>
                    <SignaturePad
                      value={formik.values.signature}
                      onChange={(signature) =>
                        formik.setFieldValue("signature", signature)
                      }
                    />

                    {formik.errors.signature && (
                      <Typography color="error" className="text-danger">
                        {formik.errors.signature}
                      </Typography>
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    {successMessage && (
                      <Dialog
                        open={showSuccessDialog}
                        onClose={handleSuccessClose}
                      >
                        <DialogTitle>Success</DialogTitle>
                        <DialogContent>
                          <Typography>{successMessage}</Typography>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleSuccessClose}>OK</Button>
                        </DialogActions>
                      </Dialog>
                    )}

                    {showAlert && submitErrorMessage && (
                      <Alert
                        severity="error"
                        onClose={() => setShowAlert(false)}
                      >
                        {submitErrorMessage}
                      </Alert>
                    )}

                    {apiErrorMessage && (
                      <Alert
                        severity="error"
                        onClose={() => setApiErrorMessage("")}
                      >
                        {apiErrorMessage}
                      </Alert>
                    )}
                    {(formik.errors.batteryOne ||
                      formik.errors.phoneNumber ||
                      formik.errors.vin ||
                      formik.errors.name) && (
                      <Typography sx={{ color: "#d32f2f" }}>
                        There are errors in the customer and asset form above
                        {Object.values(formik.errors).map((error, index) => (
                          <Typography key={index} component="div">
                            {"- "}
                            {error}
                          </Typography>
                        ))}
                      </Typography>
                    )}

                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      style={{
                        backgroundColor: "#ED7D31",
                        border: "1px solid #dddddd",
                        // color: "#ED7D31",
                        margin: "1rem",
                        padding: "1rem",
                      }}
                      endIcon={
                        formik.isSubmitting || isLoading ? (
                          <CircularProgress size={20} color="inherit" />
                        ) : (
                          <SendIcon />
                        )
                      }
                    >
                      {isSubmitting
                        ? "Registering New Sale"
                        : "Submit and Register New Sale"}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Grid>

          <Grid item>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box className="container" style={{ maxWidth: "970px" }}></Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default SalesForm;
