import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import Header from "../components/Header";
import axios from "axios";
import SalesForm from "../components/SalesForm";
import SelectReseller from "../components/SelectReseller";
import AppContext from "../components/AppContext";
import canopyAPI from "../api/canopyAPI";

function NewSale() {
  const context = useContext(AppContext);
  const reseller = localStorage.getItem("reseller");
  const outlet = localStorage.getItem("location");
  const [resellersList, setResellerList] = useState([]);

  useEffect(() => {
    if (!context.token) {
      myToken();
    } 
    else fetchResellers(context.token);
  }, [context.token]);

  const myToken = async () => {
    const data = JSON.stringify({
      secret: process.env.REACT_APP_SECRET,
      key: process.env.REACT_APP_KEY,
      grant_type: process.env.REACT_APP_GRANT_TYPE,
    });

    const apiToken = await axios({
      method: "post",
      url: `${process.env.REACT_APP_CANOPY_API_URL}/getToken`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    }).catch((error) => {
      return error.response;
    });

    if (apiToken) {
      context.setToken(apiToken.data);
    }
  };

  const fetchResellers = async (token) => {
    try {
      const response = await canopyAPI.resellers(token);
      if (response?.data) {
        setResellerList(response.data);
        console.log("resellerList:", response.data);
      } else {
        console.error("Failed to fetch resellerList");
      }
    } catch (error) {
      console.error("Error fetching resellerList:", error);
    }
  };

  return (
    <Box style={{ width: "100%", height: "100%", overflow: "auto" }}>
      <Box m="10px 5px 0 5px" display="flex" justifyContent={"space-between"}>
        <Header title={"Register Sale Module"} subtitle={"Fill form"} />
      </Box>
      {(context.reseller && context.outlet) ? (
        <SalesForm
          location={context.outlet}
          reseller={context.reseller}
          token={context.token}
        />
      ) : (
        <SelectReseller resellersList={resellersList} />
      )}
    </Box>
  );
}

export default NewSale;
