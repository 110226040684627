import { Box } from "@mui/system";
import Header from "../components/Header";
import React, { useContext, useState } from "react";
import { Grid, TextField, Button, Typography } from "@mui/material";
import { colors } from "@mui/material";
import canopyAPI from "../api/canopyAPI";
import AppContext from "../components/AppContext";

function RoamHubSupervisor() {
  const context = useContext(AppContext);
  const [error, setError] = useState({ topic: "", evseConnectorId: "" });
  const [evseConnectorId, setEvseConnectorId] = useState(
    "253939a6-5ad4-4b7c-bb27-015c0e3db5e2"
  );
  const [topic, setTopic] = useState(
    "troca-energica/structure/commands/changeConfiguration/"
  );

  const handleSend = async (topic, evseConnectorId) => {
    // console.log(topic, evseConnectorId);
    let isValid = true;
    let error = { topic: "", evseConnectorId: "" };

    if (!topic) {
      error.topic = "Topic/Command is required";
      isValid = false;
    }
    if (!evseConnectorId) {
      error.evseConnectorId = "Slot ID is required";
      isValid = false;
    }
    setError(error);

    if (isValid) {
      var payload = {
        topic: topic,
        evse: evseConnectorId,
        message: {
          $clientId: process.env.REACT_APP_MQTT_CLIENTID,
          data: {
            id: evseConnectorId,
            status: "pending",
            type: "change_configuration",
            inputParameters: {
              locations: [
                {
                  id: evseConnectorId,
                  level: "evse",
                },
              ],
              configuration: {
                evseLevel: [
                  {
                    attributeType: "Actual",
                    attributeValue: "true",
                    component: {
                      name: "BeaconLighting",
                    },
                    variable: {
                      name: "Active",
                    },
                  },
                ],
              },
            },
            results: {},
            receivedAt: new Date().toISOString(),
            lastUpdated: new Date().toISOString(),
          },
        },
      };

      const pub = await canopyAPI.controlBeacon(payload, context.token);
      if (pub && pub.status === 200) {
        alert("Beacon configuration updated successfully");
      } else {
        console.log(pub.data);
        alert("Beacon configuration failed to updated");
      }
    } else {
      alert("API error");
    }
  };

  return (
    <Box style={{ width: "100%", height: "100%", overflow: "auto" }}>
      <Box m="10px 5px 0 5px" display="flex" justifyContent={"space-between"}>
        <Header title={"Roam Hub Supervisor Module"} subtitle={"Search"} />
      </Box>
      <Grid
        container
        spacing={2}
        display="flex"
        justifyContent={"space-around"}
        m="10px 10px 20 5px"
      >
        {/* Topic Input */}
        <Grid item xs={11} sm={5} md={5}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "100%" },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              id="topic"
              label="Enter Topic/Command"
              variant="standard"
              value={topic}
              required
              onChange={(e) => setTopic(e.target.value)}
              error={!!error.topic}
              helperText={error.topic}
            />
          </Box>
        </Grid>

        <Grid item xs={11} sm={5} md={2}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "100%" },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              id="slot-id"
              label="Enter Slot ID"
              variant="standard"
              value={evseConnectorId}
              required
              onChange={(e) => setEvseConnectorId(e.target.value)}
              error={!!error.evseConnectorId}
              helperText={error.evseConnectorId}
            />
          </Box>
        </Grid>

        <Grid item xs={11} sm={5} md={2}>
          <Box
            onClick={() => handleSend(topic, evseConnectorId)}
            sx={{
              backgroundColor: "#f0b995",
              height: 56,
              borderRadius: 4,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              transition: "background-color 0.3s ease",
              "&:hover": {
                backgroundColor: "#e6cdbc",
              },
              cursor: "pointer",
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "transparent",
                color: colors.grey[100],
                boxShadow: "none",
                "&:hover": {
                  backgroundColor: "transparent",
                  boxShadow: "none",
                },
              }}
            >
              Send
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default RoamHubSupervisor;
