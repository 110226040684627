import { useTheme } from "@mui/material";
import {
  KeyboardDoubleArrowLeft,
  DashboardOutlined,
  KeyboardDoubleArrowRight,
  Receipt,
  LogoutRounded,
  ElectricBike,
  Storefront,
  Search,
  Person,
} from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Menu, MenuItem, ProSidebar } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Link } from "react-router-dom";
import { tokens } from "../../theme";
import AppContext from "../../components/AppContext";

// MENU ITEM COMPONENT
const Item = ({ title, to, selected, setSelected, icon, color }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <MenuItem
      active={selected === title}
      icon={icon}
      // onClick={() => setSelected(title)} // to be uncommented once SSO is fixed
      style={{ color: colors.grey[800] }} // to be reverted back to grey[100] once SSO is fixed
    >
      <Typography color={colors.grey[800]}>{title}</Typography> {/* to be reverted back to grey[100] once SSO is fixed */} 
      {/* <Link to={to} /> */} {/* to be uncommented once SSO is fixed */} 
    </MenuItem>
  );
};

function SidePanel() {
  const context = useContext(AppContext);
  const [selected, setSelected] = useState("Dashboard");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const location = localStorage.getItem("location");
  const reseller = localStorage.getItem("reseller");
  const userName = localStorage.getItem("userName");

  const switchVendorLocation = () => {
    localStorage.removeItem("location");
    localStorage.removeItem("location_id");
    context.setOutlet();
    context.myToken();
  };

  const appendUrl = () => {

    const hostname = window.location.hostname;

    return  hostname.split('.')[0];

  }

  const logout = () => {

    localStorage.removeItem("reseller");

    localStorage.removeItem("location");

    localStorage.removeItem("reseller_id");

    localStorage.removeItem("location_id");

    context.setReseller();

    context.setOutlet();

    window.location.replace(`https://${appendUrl()}.roam-electric.com/oauth2/sign_out`);

  };

  useEffect(() => {
    checkScreenSize();
  }, []);

  const checkScreenSize = () => {
    setIsCollapsed(window.innerWidth < 768);
    setIsSmallScreen(window.innerWidth < 768);
  };

  return (
    <Box
      style={{ flex: "auto", width: "auto", height: "auto" }}
      sx={{
        backgroundColor: colors.primary[400],
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#f0b995 !important",
        },
        "& .pro-menu-item.active": {
          color: "#ed7d31 !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <KeyboardDoubleArrowRight /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="flex-start"
                ml={0}
                mr={0}
                //   alignItems="center"
              >
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <KeyboardDoubleArrowLeft />
                </IconButton>
              </Box>
            )}

            {!isCollapsed && (
              <Box mb="1px" mt={-3}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent={"center"}
                  sx={{ mt: 1 }}
                >
                  <img
                    src={
                      isSmallScreen
                        ? "../../assets/icon.png"
                        : "../../assets/icon.png"
                    }
                    alt="#canopy"
                    width={120}
                    height={120}
                    style={{ borderRadius: "50%", cursor: "pointer" }}
                  />
                </Box>
                <Box textAlign={"center"}>
                  <Typography
                    variant="h3"
                    color={colors.grey[400]}
                    fontWeight="bold"
                    sx={{ m: "10px 0 0 0" }}
                  >
                    Canopy
                  </Typography>
                  {/* <Typography
                    variant="h6"
                    color={colors.greenAccent[400]}
                    fontWeight="600"
                    sx={{ m: "3px 0 0 0" }}
                  >
                    Administrator
                  </Typography> */}
                </Box>
              </Box>
            )}
          </MenuItem>
          <Item
            icon={<DashboardOutlined />}
            selected={selected}
            setSelected={setSelected}
            title="Dashboard"
            to="/"
          />
          <Typography
            variant="h6"
            color={colors.greenAccent[200]}
            sx={{ m: "10px 0 5px 15px" }}
          >
            Modules
          </Typography>
          <Item
            icon={<ElectricBike />}
            selected={selected}
            setSelected={setSelected}
            title="Register New Sale"
            to="/new-sale"
          />
          <Item
            icon={<Receipt />}
            selected={selected}
            setSelected={setSelected}
            title="Sales List"
            to="/sales-list"
          />
          <Item
            icon={<Search />}
            selected={selected}
            setSelected={setSelected}
            title="Asset Lookup"
            to="/asset-lookup"
          />
          <Item
            icon={<Person />}
            selected={selected}
            setSelected={setSelected}
            title="Roam Hub Supervisor"
            to="/roamhub-supervisor"
          />
          {/* to be uncommented once SSO is fixed */} 
          <MenuItem
            style={{
              position: "absolute",
              bottom: "10px",
              left: "-20px",
              color: colors.grey[100],
            }}
          >
            {/* {!isCollapsed && (
              <>
                <Box>
                  <Item
                    icon={<Storefront />}
                    selected={selected}
                    setSelected={setSelected}
                    title={
                      <Typography
                        color={colors.grey[400]}
                        // fontWeight={"600"}
                        fontSize={14}
                      >
                        Logged In as {userName}
                      </Typography>
                    }
                  />
                </Box>

                {location && reseller && (
                  <Box
                    onClick={switchVendorLocation}
                    display="flex"
                    justifyContent="center"
                    ml={0}
                    mr={0}
                    // alignItems="center"
                  >
                    <Typography fontSize={10} color="#ed7d31">
                      ({location}) Switch outlet location?
                    </Typography>
                  </Box>
                )}
              </>
            )} */}
            {/* <Box onClick={logout}> */}
            <Box onClick={() => {
              localStorage.removeItem("sc_token")
              window.location.replace("/");
              }}>
              <MenuItem
                active={selected === "Logout"}
                icon={<LogoutRounded />}
                style={{ color: "red" }}
              >
                <Typography color={"red"}>
                  {isCollapsed ? "" : "Logout"}
                </Typography>
              </MenuItem>
            </Box>
          </MenuItem>
          <Box paddingLeft={isCollapsed ? undefined : "10%"}></Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
}

export default SidePanel;
