import React, { useContext, useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import AppContext from "./AppContext";
import canopyAPI from "../api/canopyAPI";

function SelectReseller({ resellersList }) {
  const context = useContext(AppContext);
  const [error, setError] = useState("");
  const [selectedReseller, setSelectedReseller] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedLocationId, setSelectedLocationId] = useState();
  const [locationsList, setLocationsList] = useState([]); 
  const resellerId = localStorage.getItem("reseller_id");
  const filteredResellersList = resellersList?.filter((reseller) => reseller?.id === Number(resellerId));

  useEffect(() => {
    if (filteredResellersList[0]?.id) {
      setSelectedReseller(filteredResellersList[0]?.name);
      fetchLocations({ id: filteredResellersList[0]?.id }, context.token);
      localStorage.setItem("reseller_id", filteredResellersList[0]?.id);
    }
  }, [resellersList, resellerId])

  const fetchLocations = async (values, token) => {
    try {
      const response = await canopyAPI.locations(values, token);
      console.log("API Response:", response); // Inspect the full response object
      const data = response.data; // Access data only if the structure is confirmed
      console.log("Locations response: ", data);
      setLocationsList(response?.data.map((location) => location));
      return data;
    } catch (error) {
      console.error("Error fetching locations:", error);
      return null;
    }
  };

  const handleProceed = () => {
    if (selectedReseller && selectedLocation) {
      setError("");
      const _location = locationsList?.find(
        (location) => location.name === selectedLocation
      );
      const reseller = resellersList?.find(
        (reseller) => reseller.name === selectedReseller
      );
      context.setReseller(reseller?.id);
      context.setOutlet(_location);
      localStorage.setItem("reseller", selectedReseller);
      localStorage.setItem("location", selectedLocation);
      console.log("location list", locationsList);
    } else {
      setError("Please select a Vendor and your Outlet");
    }
  };
  console.log("resellerList", resellersList);


  const handleLocationChange = (e) => {
    const selectedLocationId = e.target.value;
    const location = locationsList.find(location => location.id === parseInt(selectedLocationId, 10));
    setSelectedLocation(location.name);
    localStorage.setItem("location_id", location.id);

  };
  

  // Function to handle selection of reseller
  const handleResellerChange = (e) => {
    const selectedResellerName = e.target.value;
    const reseller = resellersList.find(
      (reseller) => reseller.name === selectedResellerName
    );
    setSelectedReseller(selectedResellerName);
    if (reseller) {
      fetchLocations({ id: reseller.id }, context.token);
      localStorage.setItem("reseller_id", reseller.id);

    } else {
      setLocationsList([]);
    }
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      height="70vh"
    >
      <Typography variant="h1" textAlign={"center"}>
        Select which Vendor you work for and the Outlet location where you are
        registering a sale
      </Typography>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent={"center"}
      >
        <Box>
          <select
            style={{
              padding: "10px",
              fontSize: "16px",
              border: "1px solid #ccc",
              borderRadius: "5px",
              margin: "10px 10px",
              width: "200px",
            }}
            value={selectedReseller}
            onChange={handleResellerChange} // Use the handleResellerChange function
          >
            {/* <option value="">Select a Vendor</option> */}
            {filteredResellersList?.map((reseller, index) => (
              <option key={index} value={reseller.name} selected>
                {reseller.name}
              </option>
            ))}
          </select>
        </Box>

        <Box>
          <select
            style={{
              padding: "10px",
              fontSize: "16px",
              border: "1px solid #ccc",
              borderRadius: "5px",
              margin: "10px 10px",
              width: "200px",
            }}
            value={selectedLocation}
            onChange={(e) => setSelectedLocation(e.target.value)}
          >
            <option value="">Select outlet location</option>
            {locationsList.map((location, index) => (
              <option key={index} value={location?.name}>
                {location?.name}
              </option>
            ))}
          </select>
        </Box>

        <Box>
          <button
            style={{
              margin: "10px",
              padding: "10px 20px",
              fontSize: "18px",
              backgroundColor: "#ED7D31",
              color: "white",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            onClick={handleProceed}
          >
            Save & Proceed
          </button>
        </Box>
      </Grid>

      {error && (
        <Typography variant="subtitle1" color="error">
          {error}
        </Typography>
      )}
    </Grid>
  );
}

export default SelectReseller;
