import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Header from "../components/Header";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { Grid } from "@mui/material";
import { Search } from "@mui/icons-material";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import SendIcon from "@mui/icons-material/Send";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import QrReader from "react-qr-scanner";
import canopyAPI from "../api/canopyAPI";
import AppContext from "./AppContext";

// Define qrscanner_device function outside the component
const qrscanner_device = (
  setQrCode,
  setSecondarySerialnumber,
  setRedirect,
  handleSecondarySerialnumber
) => {
  let qrCode_scannerDevice = "";
  let interval;

  document.addEventListener("keydown", function (evt) {
    if (interval) clearInterval(interval);
    if (evt.code === "Enter") {
      if (qrCode_scannerDevice) handleQRCode(qrCode_scannerDevice);
      qrCode_scannerDevice = "";
      return;
    }
    if (evt.key !== "Shift") qrCode_scannerDevice += evt.key;
    interval = setInterval(() => (qrCode_scannerDevice = ""), 20);
  });

  function handleQRCode(scannedQRCode) {
    setQrCode(scannedQRCode);
    setSecondarySerialnumber({
      value: scannedQRCode,
      regex: true,
      Duplicate: true,
    });

    handleSecondarySerialnumber({
      value: scannedQRCode,
      regex: true,
      Duplicate: true,
    });

    setRedirect(true);
  }
};

function QrcSerialNumberDiagnostics() {
  const context = useContext(AppContext);

  const navigate = useNavigate(); // Initialize useNavigate hook
  //const history = useHistory();
  const [qrCode, setQrCode] = useState(null);
  const [diagnosticspage, setDiagnosticspage] = useState([]);
  const location = useLocation();
  const { primarySerialnumber } = location.state || {};
  const [loading, setLoading] = useState(false); // Add loading state
  //const setDiagnosticspage = location.setDiagnosticspage;
  const [redirect, setRedirect] = useState(false);

  const [secondarySerialnumber, setSecondarySerialnumber] = useState({
    value: "QR Serial number not found",
    regex: true,
    Duplicate: true,
  });

  console.log("primaryserialnumber ", primarySerialnumber);
  // useEffect(() => {
  //   console.log(
  //     "inside the useEffect() secondaryserialnumber",
  //     secondarySerialnumber.value
  //   );
  //   // handSecondarySerialnumber();
  //   setQrCode(secondarySerialnumber.value);
  //   setLoading(false);
  // }, [secondarySerialnumber]);

  const handleScan = (data) => {
    console.log("from upper data ", data);
    if (data) {
      console.log("from inner data ", data.text);
      setQrCode(data.text);
      setSecondarySerialnumber({
        value: data.text,
        regex: true,
        Duplicate: true,
      });
      console.log(
        "after set secondaryserialnumber",
        secondarySerialnumber.value
      );

      handleSecondarySerialnumber({
        value: data.text,
        regex: true,
        Duplicate: true,
      });
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  const previewStyle = {
    height: 240,
    width: 320,
  };

  const handleSecondarySerialnumber = (data) => {
    //if primaryserial number exists
    if (primarySerialnumber !== "CAN Serial number not found") {
      //check if the QRserialnumber exists
      console.log("secondaryserialnumber", data.value);
      canopyAPI.loggerService(
        {
          logs: `serial number scanned from the QR code is ${data.value}`,
          severity: "debug",
        },
        sc_token
      );

      if (data.value === "QR Serial number not found") {
        //--------->send to diagnostics page and print out R1
        setDiagnosticspage("R1");
      } else {
        //QRCserial number exists
        //check if it matches with primaryserialnumber
        if (primarySerialnumber === data.value) {
          //------> send user to diagnostics page and give R2
          let regexMatch = matchesFormat_forQRC(data.value);
          setDiagnosticspage("R2");
          if (regexMatch) {
            setRedirect(true);
            // handleRedirect();
          }
        } else {
          //--------> send user to diagnostics page and give R3
          let regexMatch = matchesFormat_forQRC(data.value);
          setDiagnosticspage("R3");
          if (regexMatch) {
            setRedirect(true);
            // handleRedirect();
          }
        }
      }
    } else {
      //primary serial number doesnt exist
      //check if QRserialnumber exists
      console.log("here we are for secondaryserial number", data);
      console.log("here we are for primaryserial number", primarySerialnumber);
      if (data.value === "QR Serial number not found" && data.value !== null) {
        //QRCserialnumber doesnt exist
        console.log("issue new serial number and output R7");
        handleIssueNewSerialNumber("R7");

        //--------> send user to diagnostics page and give R7
      } else {
        //QRCSerialnumber exists
        //check regex
        console.log("going to output R6 or R5");
        let regexMatch = matchesFormat_forQRC(data.value);
        //check if regex is okay or not
        if (regexMatch === true) {
          //--------> send user to diagnostics page and give R5
          setDiagnosticspage("R5");
          console.log("output R5");
          console.log("on output 5 ", data.value);
          setSecondarySerialnumber({
            value: data.value,
            regex: true,
            Duplicate: true,
          });
          setRedirect(true);
          // handleRedirect();
        } else {
          //issue new serial number
          handleIssueNewSerialNumber("R6");
          console.log("issue new serial number and output R6");
          //--------> send user to diagnostics page and give R6
        }
      }
    }
  };

  const handleIssueNewSerialNumber = async (reason) => {
    // const myRegex = /^SJV1\.1BAK45A3\d{3}-\d{4}$/;

    function generateRandomSerial() {
      return Math.floor(Math.random() * 10000)
        .toString()
        .padStart(4, "0");
    }

    // Generate random serial numbers
    const randomSerial1 = Math.floor(Math.random() * 1000)
      .toString()
      .padStart(3, "0"); // Generate a three-digit number for randomSerial1
    const randomSerial2 = generateRandomSerial(); // Generate a four-digit number for randomSerial2

    const myNewSerial = `SJV1.1BAK45A3${randomSerial1}-${randomSerial2}`;

    console.log("QRC Token", sc_token);
    const saveSerial = await canopyAPI.saveNewBatterySerial(
      {
        battery_serial: myNewSerial,
        // batch: "Batch_1",
        // batch_size: "60.0",
        // batch_receiving_date: "2023-01-01 00:00:00",
      },
      sc_token
    );

    canopyAPI.loggerService(
      {
        logs: "New Serial number has been issued by Canopy",
        severity: "debug",
      },
      sc_token
    );

    // console.log(saveSerial.data);

    if (saveSerial?.status === 200) {
      navigate("/diagnostics-report", {
        state: {
          diagnostics: reason,
          primaryserialnumber: primarySerialnumber,
          secondaryserialnumber: "No QRC",
          new_serial_number: myNewSerial,
        },
      });
    } else {
      console.log("Issued Serial Number already exists, generate a new one");
    }
  };

  function matchesFormat_forQRC(input) {
    const regex = /^SJV1\.1BAK45A\d{4}-\d{4}$/;
    const isFormatValid = regex.test(input);

    setSecondarySerialnumber((prevState) => ({
      ...prevState,
      regex: isFormatValid,
    }));
    return isFormatValid;
  }

  const handleRedirect = () => {
    console.log("state", {
      diagnostics: diagnosticspage,
      primaryserialnumber: primarySerialnumber,
      secondaryserialnumber: secondarySerialnumber,
    });

    navigate("/diagnostics-report", {
      state: {
        diagnostics: diagnosticspage,
        primaryserialnumber: primarySerialnumber,
        secondaryserialnumber: secondarySerialnumber,
      },
    });
  };

  useEffect(() => {
    if (redirect === true) {
      handleRedirect();
    }
  }, [redirect]);

  let sc_token = localStorage.getItem("sc_token");

  if (!sc_token) {
    localStorage.removeItem("sc_token");
    window.location.replace("/");
  }

  const handleDiagnosticsPageClick = () => {
    if (primarySerialnumber !== "CAN Serial number not found") {
      setDiagnosticspage("R1");
      navigate("/diagnostics-report", {
        state: {
          diagnostics: ["R1"],
          primaryserialnumber: primarySerialnumber,
          secondaryserialnumber: "No QRC",
        },
      });
    } else if (secondarySerialnumber.value === "QR Serial number not found") {
      // QRC serial number doesn't exist when BMS serial number also doesn't exist
      console.log("Issue new serial number and output R7");
      handleIssueNewSerialNumber("R7");
    }

    console.log("state", {
      diagnostics: ["R1"],
      primaryserialnumber: primarySerialnumber,
      secondaryserialnumber: secondarySerialnumber.value,
    });

    console.log("Diagnostics Page button clicked");
  };

  const handleScanButtonClick = () => {
    setLoading(true); // Set loading state to true when button is clicked
    qrscanner_device(
      setQrCode,
      setSecondarySerialnumber,
      setRedirect,
      handleSecondarySerialnumber
    );
  };

  const handleScanComplete = () => {
    setLoading(false); // Set loading state to false when scanning is complete
  };

  return (
    <Box style={{ width: "100%", height: "100%", overflow: "auto" }}>
      <Box m="10px 5px 0 5px" display="flex" justifyContent={"space-between"}>
        <Header title={"QRC Serial Number"} subtitle={"Search"} />
      </Box>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={6} md={6}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            component="form"
            sx={{
              "& .MuiTypography-root": { m: 1, width: "100%" },
            }}
            noValidate
            autoComplete="off"
          >
            <QrReader
              delay={100}
              style={previewStyle}
              onError={handleError}
              onScan={handleScan}
            />
            <Typography variant="body1">
              {qrCode ? qrCode : "No QR code scanned"}
            </Typography>

            {/* Conditional rendering based on loading state */}
            {loading ? (
              <CircularProgress />
            ) : (
              <Button
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: "#ED7D31",
                  border: "1px solid #dddddd",
                  margin: "0 0.5rem",
                  padding: "1rem",
                }}
                onClick={handleScanButtonClick}
              >
                Scan using QR scanner
              </Button>
            )}

            {/* Your existing button here */}
            <Button
              variant="contained"
              color="primary"
              style={{
                backgroundColor: "#ED7D31",
                border: "1px solid #dddddd",
                margin: "0 0.5rem",
                padding: "1rem",
              }}
              endIcon={<SendIcon />}
              onClick={handleDiagnosticsPageClick}
            >
              Diagnostics Page
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default QrcSerialNumberDiagnostics;
