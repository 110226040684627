import axios from "axios";

const sendOTPContacts = async (values, token) => {
  return await axios({
    method: "post",
    url: `${process.env.REACT_APP_CANOPY_API_URL}/warranty/sendSms`,
    headers: {
      "api-token": token,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "X-API-Key, ",
    },
    data: JSON.stringify(values),
  }).catch((error) => {
    return error.response;
  });
};

export { sendOTPContacts };
