import { Box, Grid, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import Header from "../components/Header";
import { Search } from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import { tokens } from "../theme";
import DisplayList from "../components/DisplayList";
import TextField from '@mui/material/TextField';
import canopyAPI from "../api/canopyAPI";
import AppContext from "../components/AppContext";

function AssetLookup() {
  const context = useContext(AppContext)
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [vin, setVin] = useState(); 
  const [data, setData] = useState();
  const [noAsset, setNoAsset] = useState(false)

 const handleSearch = async() => {
  setData()
  setNoAsset(false)
  
  try {
  const vinResponse = await canopyAPI.vinLookup(vin, context.token)
  if(vinResponse.status === 200){
   setData(vinResponse.data)
  }
  else{
    setNoAsset(true)
  }
  
  } catch (error) {
    setNoAsset(true)
    console.log(error)
  }
 }


  return (
    <Box style={{ width: "100%", height: "100%", overflow: "auto" }}>
      <Box m="10px 5px 0 5px" display="flex" justifyContent={"space-between"}>
        <Header title={"Asset Lookup Module"} subtitle={"Search"} />
      </Box>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={6} md={6}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '100%' },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              id="standard-search"
              label="Enter Roam Air Vehicle Identification Number"
              type="search"
              variant="standard"
              value={vin} 
              onChange={(e) => setVin(e.target.value)}
            />
            
          </Box>
          
          <Typography style={{color: 'red', fontSize: 10, textAlign: 'left',}}>
              {(vin && vin.length !== 17) || (vin && !vin.match(/^[A-Za-z0-9\s]+$/)) ? "Vehicle Identification Number number must be exactly 17 characters and should only contain letters and numbers" : undefined}
            </Typography>

            <Typography style={{color: 'red', fontSize: 10, textAlign: 'left',}}>
              {noAsset && "No Asset Found"}
            </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Box
         
            onClick={handleSearch} 
            sx={{
              backgroundColor: "#f0b995",
              height: 56,
              borderRadius: 4,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              transition: "background-color 0.3s ease",
              "&:hover": {
                backgroundColor: "#e6cdbc",
              },
            }}
          >
            <Search
              style={{
                fontSize: 30,
                color: colors.grey[100],
              }}
            />
          </Box>
        </Grid>
      </Grid>
      {data && <Grid container justifyContent="center">
        <Grid item xs={12} sm={8} md={6}>
          <DisplayList data={{ Service_Number: data.Service_Number, Vin: data.Vin, Customer: data.Customer, Item_Number: data.Item_Number, Details: data.Details }} />
        </Grid>
      </Grid>}
    </Box>
  );
}

export default AssetLookup;
