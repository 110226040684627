import React, { useContext, useState } from "react";
import {
  Button,
  TextField,
  Grid,
  Typography,
  Container,
  Paper,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import AppContext from "../components/AppContext";

// Define colors
const black = "#000";
const orange = "#ED7D31";
const white = "#fff";

// Styled components
const PaperStyled = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: white,
  color: black,
  borderRadius: "12px",
  boxShadow: theme.shadows[5],
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  minHeight: "400px",
  maxWidth: "400px",
  margin: "auto",
  marginTop: theme.spacing(8),
}));

const ButtonStyled = styled(Button)(({ theme }) => ({
  backgroundColor: orange,
  color: white,
  padding: theme.spacing(1.5),
  borderRadius: "8px",
  "&:hover": {
    backgroundColor: "#ED7D31",
  },
}));

const TypographyStyled = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const Login = () => {
  const context = useContext(AppContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({ email: "", password: "" });
  const [generalError, setGeneralError] = useState("");
  const navigate = useNavigate();

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleLogin = async () => {
    let valid = true;
    let newErrors = { email: "", password: "" };
    setGeneralError("");

    // Simple form validation
    if (!email) {
      newErrors.email = "Email is required";
      valid = false;
    } else if (!email.includes("@")) {
      newErrors.email = "Invalid email address";
      valid = false;
    }

    if (!password) {
      newErrors.password = "A password is required";
      valid = false;
    }

    if (valid) {
      setErrors({ email: "", password: "" });
      if (
        email === "support@roam-electric.com" &&
        password === "Se_14-Campaign!"
      ) {
        await context.myToken();
        navigate("/battery-serialnumber-diagnostics");
      } else {
        setGeneralError("Invalid email or password");
      }
    } else {
      setErrors(newErrors);
    }
  };

  return (
    <Container maxWidth="xs">
      <PaperStyled elevation={3}>
        <TypographyStyled variant="h4" gutterBottom>
          Welcome Back!
        </TypographyStyled>
        <Typography
          variant="body1"
          color="textSecondary"
          gutterBottom
          style={{ paddingBottom: 15 }}
        >
          Please log in to your account.
        </Typography>
        {generalError && (
          <Typography
            variant="body2"
            color="error"
            gutterBottom
            style={{ paddingBottom: 15 }}
          >
            {generalError}
          </Typography>
        )}
        <Grid container spacing={2} direction="column">
          <Grid item>
            <TextField
              fullWidth
              label="Email"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              InputProps={{ style: { color: black } }}
              InputLabelProps={{ style: { color: black } }}
              error={!!errors.email}
              helperText={errors.email}
              sx={{ marginBottom: "16px" }}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              label="Password"
              type={showPassword ? "text" : "password"}
              variant="outlined"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                style: { color: black },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      edge="end"
                      color="inherit"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{ style: { color: black } }}
              error={!!errors.password}
              helperText={errors.password}
            />
          </Grid>
          <Grid item>
            <ButtonStyled fullWidth onClick={handleLogin}>
              Log In
            </ButtonStyled>
          </Grid>
        </Grid>
      </PaperStyled>
    </Container>
  );
};

export default Login;
